<template>
  <div class="pinTu-bigwrap">
    <div class="kongbai"></div>
    <div class="pinTu-wrap">
      <ul>
        <li @click="$lv.go('/game/components/pinTu/components/pinTuIndex')">开始游戏</li>
        <li>帮助</li>
        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pinTu'
}
</script>
<style lang="stylus">
.kongbai
  height 1rem
.pinTu-bigwrap
  height 8rem !important
  width 100%
  background-color green
  position relative
.pinTu-wrap
  width 94%
  margin-left 3%
  height 3rem
  background-color blue
  color #fff
  ul
    list-style none
    li
      height 1rem
      line-height 1rem
      text-indent 1em
      border-bottom 0.02rem solid pink
</style>
